import { mapGetters } from "vuex"
import moment from "moment-timezone"
import { ElMessageBox } from "element-plus";

export default {
  computed: {
    ...mapGetters("profile", ["getProfileData"]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    isBillingUser() {
      const billingUserTypes = [
        "AR_LEAD",
        "AR_EXECUTIVE",
        "CP_LEAD",
        "CODING_LEAD",
        "CODING_EXECUTIVE",
        "POSTING_EXECUTIVE",
        "SR_FTE",
        "BILLING_REPRESENTATIVE"
      ];
    
      if (this.getAuthenticatedUser && billingUserTypes.includes(this.getAuthenticatedUser.user_type)) {
        return false;
      }
      return true;
    },    
    isSuperAdmin() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        this.getAuthenticatedUser.user_type == "LAB_SUPER_ADMIN"
      ) {
        return true
      }
      return false
    },
    isHospitalUser() {
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.user_type) {
        let users = [
          "HOSPITAL_ADMIN",
          "HOSPITAL_ACCOUNT_MANAGER",
          "HOSPITAL_MARKETING_MANAGER"
        ];
        if (users.indexOf(this.getAuthenticatedUser.user_type) !== -1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    isFacilityUser() {
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.user_type) {
        let users = [
          "HOSPITAL_PHYSICIAN",
          "HOSPITAL_ADMIN",
          "HOSPITAL_ACCOUNT_MANAGER",
          "MARKETER",
          "HOSPITAL_MARKETING_MANAGER",
          "BILLING_REPRESENTATIVE",
          "AR_LEAD",
          "AR_EXECUTIVE",
          "CP_LEAD",
          "CODING_LEAD",
          "CODING_EXECUTIVE",
          "POSTING_EXECUTIVE",
          "SR_FTE",
          "SALES_DIRECTOR"
        ];
        if (users.indexOf(this.getAuthenticatedUser.user_type) !== -1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    isPhysicianUser() {
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.user_type) {
        let users = [
          "HOSPITAL_PHYSICIAN",
          "MARKETER",
          "BILLING_REPRESENTATIVE",
          "AR_LEAD",
          "AR_EXECUTIVE",
          "CP_LEAD",
          "CODING_LEAD",
          "CODING_EXECUTIVE",
          "POSTING_EXECUTIVE",
          "SR_FTE",
        ];
        if (users.indexOf(this.getAuthenticatedUser.user_type) !== -1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    isLabAssistantUser() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        (this.getAuthenticatedUser.user_type == "LAB_DATA_ASSISTANT"||
          this.getAuthenticatedUser.user_type == "LAB_DATA_ADMIN")
      ) {
        return true
      }
      return false
    },
    isLabTechnologistUser() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        this.getAuthenticatedUser.user_type == "LAB_TECHNOLOGIST"
      ) {
        return true
      }
      return false
    },
    isOrderingPhysician() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type == "HOSPITAL_PHYSICIAN"
      ) {
        return true
      }
      return false
    },
    checkDLWLab() {
      if (
        this.getProfileData &&
        this.getProfileData.lab &&
        this.getProfileData.lab.lab_code === "DLW"
      ) {
        return true;
      }
      return false;
    },
    isLabAdmin() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        this.getAuthenticatedUser.user_type == "LAB_ADMIN"
      ) {
        return true
      }
      return false
    },
    isLabUser() {
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.user_type) {
        let users = [
          "LAB_ADMIN",
          "LAB_MANAGER",
          "BILLING_REPRESENTATIVE",
          "LAB_DATA_ASSISTANT",
          "LAB_SUPER_ADMIN",
          "LAB_DATA_ADMIN"
        ];
        if (users.indexOf(this.getAuthenticatedUser.user_type) !== -1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    isReleasePending(){
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.user_type) {
        let users = [
            "HOSPITAL_ADMIN",
            "HOSPITAL_ACCOUNT_MANAGER",
            "HOSPITAL_PHYSICIAN",
            "MARKETER",
            // "LAB_SUPER_ADMIN"
        ];
        if (users.indexOf(this.getAuthenticatedUser.user_type) !== -1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    isAccountManager() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type &&
        this.getAuthenticatedUser.user_type == "ACCOUNT_MANAGER"
      ) {
        return true
      }
      return false
    },
    isAbletoAddInsurance() {
      let user = this.getAuthenticatedUser;
      if (
        user &&
        user.username &&
        ['prasadadmin'].includes(user.username)
      ) {
        return true;
      }
      return false;
    },
    isAbletoGeneratePathologyReport() {
      let user = this.getAuthenticatedUser;
      if (
        user &&
        user.username &&
        ['pathologist','robert john'].includes(user.username)
      ) {
        return true;
      }
      return false;
    },
    
  },
  methods: {
    convertToUppercase(str) {
      return str ? str.toUpperCase() : "--";
    },   
    allowAlphaNumericSpace(e) {
      var code = "charCode" in e ? e.charCode : e.keyCode
      if (
        !(code == 32) && // space
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)
      ) {
        // lower alpha (a-z)
        e.preventDefault()
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[A-Za-z\s]+$/.test(char)) return true // Match with regex
      else e.preventDefault() // If not match, don't add to input text
    },
    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault()
      }
    },
    isInactiveStatus(data) {
      return data.status && data.status == "INACTIVE" ? true : false
    },
    dateWithMomentUTC(value, format = "MM-DD-YYYY") {
      if (!value) return
      return moment(value).utc().format(format)
    },
    momentWithTimezone: function (value, format = "MM-DD-YYYY") {
      if (!value) return ""
      let timeZone =
        this.getProfileData &&
          this.getProfileData.lab &&
          this.getProfileData.lab.timezone
          ? this.getProfileData.lab.timezone
          : "America/New_York"
      return moment(value).tz(timeZone).format(format)
    },
    momentWithTimezoneTime: function (value, format = "MM-DD-YYYY hh:mm A") {
      if (!value) return ""
      let timeZone =
        this.getProfileData &&
          this.getProfileData.lab &&
          this.getProfileData.lab.timezone
          ? this.getProfileData.lab.timezone
          : "America/New_York"
      return moment(value).tz(timeZone).format(format)
    },
    sortByKey(data, key = "name") {
      if (data && data.length) {
        data.sort((a, b) => {
          const nameA = a[key].toUpperCase()
          const nameB = b[key].toUpperCase()

          if (nameA > nameB) {
            return 1
          } else if (nameA < nameB) {
            return -1
          }
          return 0
        })
      }
      return data
    },

    getStateNameByCode(code) {
      let stateByCode = {
        AL: "Alabama",
        AK: "Alaska",
        AS: "American Samoa",
        AZ: "Arizona",
        AR: "Arkansas",
        CA: "California",
        CO: "Colorado",
        CT: "Connecticut",
        DE: "Delaware",
        DC: "District Of Columbia",
        FM: "Federated States Of Micronesia",
        FL: "Florida",
        GA: "Georgia",
        GU: "Guam",
        HI: "Hawaii",
        ID: "Idaho",
        IL: "Illinois",
        IN: "Indiana",
        IA: "Iowa",
        KS: "Kansas",
        KY: "Kentucky",
        LA: "Louisiana",
        ME: "Maine",
        MH: "Marshall Islands",
        MD: "Maryland",
        MA: "Massachusetts",
        MI: "Michigan",
        MN: "Minnesota",
        MS: "Mississippi",
        MO: "Missouri",
        MT: "Montana",
        NE: "Nebraska",
        NV: "Nevada",
        NH: "New Hampshire",
        NJ: "New Jersey",
        NM: "New Mexico",
        NY: "New York",
        NC: "North Carolina",
        ND: "North Dakota",
        MP: "Northern Mariana Islands",
        OH: "Ohio",
        OK: "Oklahoma",
        OR: "Oregon",
        PW: "Palau",
        PA: "Pennsylvania",
        PR: "Puerto Rico",
        RI: "Rhode Island",
        SC: "South Carolina",
        SD: "South Dakota",
        TN: "Tennessee",
        TX: "Texas",
        UT: "Utah",
        VT: "Vermont",
        VI: "Virgin Islands",
        VA: "Virginia",
        WA: "Washington",
        WV: "West Virginia",
        WI: "Wisconsin",
        WY: "Wyoming",
      }
      let stateName = ""
      Object.keys(stateByCode).map((key) => {
        if (key == code) {
          stateName = stateByCode[key].toUpperCase()
        }
      })
      return stateName
    },
    checkTotal(total, currentPage, pageSize) {
      return total >= (currentPage - 1) * pageSize + 1
    },
    getCustomFormattedDate(date) {
      if (!date) return
      let dateTime = date.split("T")
      if (dateTime && dateTime.length && dateTime[0]) {
        let dateData = dateTime[0].split("-")
        if (dateData && dateData.length == 3) {
          return dateData[1] + "-" + dateData[2] + "-" + dateData[0]
        }
      }
      return
    },
    getCustomFormattedTime(date, meridianUpper = false) {
      if (!date) return
      let dateTime = date.split("T")
      if (dateTime && dateTime.length && dateTime[1]) {
        let dateData = dateTime[1].split(":")
        if (dateData && dateData[0] && dateData[1]) {
          return (
            this.getHoursFormat(dateData[0]) +
            ":" +
            dateData[1] +
            " " +
            this.getTimeMeridian(dateData[0], meridianUpper)
          )
        }
      }
      return
    },
    getTimeMeridian(hrs, isUpper = false) {
      let meridian = "am"
      if (hrs > 12) {
        meridian = "pm"
      }

      if (isUpper) {
        return meridian.toUpperCase()
      } else {
        return meridian
      }
    },
    getHoursFormat(hrs) {
      if (hrs > 12) {
        return hrs - 12
      }
      return hrs
    },
    convertToLabUTC(dateTime, end_date = false) {
      if (!dateTime) return
      let clientTz = moment.tz.guess()

      let labTimezone = this.getProfileData.lab.timezone
      let clientTime = moment(dateTime).tz(clientTz)

      let h = clientTime.hour()
      let mn = clientTime.minutes()
      let s = clientTime.seconds()

      let y = clientTime.year()
      let d = clientTime.date()
      let m = clientTime.month()
      if (end_date) {
        return moment()
          .tz(labTimezone)
          .set({
            hours: h,
            minutes: mn,
            year: y,
            seconds: s,
            date: d,
            month: m,
          })
          .utc()
          .endOf("day")
          .format()
      } else {
        return moment()
          .tz(labTimezone)
          .set({
            hours: h,
            minutes: mn,
            year: y,
            seconds: s,
            date: d,
            month: m,
          })
          .utc()
          .format()
      }
    },
    getLabTimeZoneData(dateTime) {
      let clientTz = moment.tz.guess()

      let clientTime = moment(dateTime).tz(clientTz)

      let h = clientTime.hour()
      let mn = clientTime.minutes()
      let s = clientTime.seconds()

      let y = clientTime.year()
      let d = clientTime.date()
      let m = clientTime.month()

      return {
        hours: h,
        minutes: mn,
        year: y,
        seconds: s,
        date: d,
        month: m,
      }
    },
    convertLabUTCToClient(dateTime) {
      let labTimezone = this.getProfileData.lab.timezone
      return new Date(dateTime).toLocaleString("en-us", {
        timeZone: labTimezone,
      })
    },
    getLabCurrentDay() {
      return moment().tz(this.getProfileData.lab.timezone).utc().format()
    },
    getLabDefaultTime() {
      const currentDate = moment(new Date())
      return moment()
        .tz(this.getProfileData.lab.timezone)
        .set({
          hours: 10,
          minutes: 0,
          seconds: 0,
          year: currentDate.year(),
          date: currentDate.date(),
          month: currentDate.month(),
        })
        .utc()
        .format()
    },
    getPatientNameByInfo(patientInfo) {
      if (!patientInfo) return
      let name = []
      if (patientInfo.first_name) {
        name.push(patientInfo.first_name)
      }
      if (patientInfo.middle_name) {
        name.push(patientInfo.middle_name)
      }
      if (patientInfo.last_name) {
        name.push(patientInfo.last_name)
      }
      return name.join(" ")
    },
    copyTitle(titleData) {
      const title = titleData

      navigator.clipboard
        .writeText(title)
        .then(() => {
          console.log("Title copied to clipboard:", title)
        })
        .catch((error) => {
          console.error("Failed to copy title to clipboard:", error)
        })
    },

    formatDate(date, format = "MM-DD-YYYY") {
      if (date) {
        return moment.utc(date).format(format);
      } else {
        return "--";
      }
    },
    scrollToFirstError() {
      let el = this.$el.getElementsByClassName("err")[0];

      if (el) {
        el.scrollIntoView({ block: "center" });
      }
    },
    showConfirmationDialog() {
      return ElMessageBox({
        message: "We have observed that the existing insurance is INACTIVE. If you want to replace the insurance, please click Confirm.",
        title: "Warning",
        confirmButtonText: "Confirm",
        showCancelButton: false, // Hide cancel button
        type: "warning",
      });
    }
  },
}
